import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"

const CloudITSolutions = ({ data }) => (
  <Layout>
    <Seo
      title="Cloud IT Solutions"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>Cloud IT Solutions</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <p>
              Cloud computing essentially means your data is stored in an
              external server. This ensures that your data is safe and secure
              and managed by the systems and networks on that external system.
              Provided you have access to the internet from your applicable
              device, you may access your data. Externally managed services also
              reduces the amount of investment you are required to make in
              premise based servers and storage devices.
            </p>
            <p>
              Cloud services are not all created equal and a successful cloud
              solution is based on deploying the right cloud services and
              resources for each specific need. Cloud is the current advancement
              in technology services and ultimately will service present and
              future application and data needs for all individuals and
              business.
            </p>
            <p>
              Since the cloud resources vary in size and scope, weather SaaS
              (Software as a Service), PaaS (Platform as a Service), or IaaS
              (Infrastructure as a Service), selecting the right solution
              matters. Contact IT Specialists and we will ensure you get the
              right solution for your business.
            </p>
            <h4>Questions to Evaluate:</h4>
            <ol>
              <li>Is a cloud solution right for your business?</li>
              <li>
                What are the specifics you are seeking from your cloud provider?
              </li>
              <li>
                Do I need a private solution, Hybrid Solution or something else?
              </li>
              <li>
                They tell me I need to move to the Cloud. How do I know where to
                start?
              </li>
              <li>How secure is the Cloud? What kind of security do I need?</li>
              <li>
                How does a Cloud Solution effect my business requirements?
              </li>
            </ol>
            <h4>Why Cloud IT Solutions?</h4>
            <ul>
              <li>
                <span className="text-primary font-weight-bold">Email</span> -
                No more hosting your Exchange in your server room; move your
                email to the cloud.
              </li>
              <li>
                <span className="text-primary font-weight-bold">Backup</span> -
                Backup and disaster recovery services for maximum reliability.
              </li>
              <li>
                <span className="text-primary font-weight-bold">Servers</span> -
                Many businesses no longer need servers in their office. Does
                yours?
              </li>
              <li>
                <span className="text-primary font-weight-bold">Managed</span> -
                Managed private clouds bring the best of the cloud and
                on-premises together.
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default CloudITSolutions

export const CloudITSolutionsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
